@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

$transition: 0.5s;

.overlay {
  display: flex;
  position: fixed;
  top: env(safe-area-inset-top, 0);
  right: env(safe-area-inset-right, 0);
  bottom: env(safe-area-inset-bottom, 0);
  left: env(safe-area-inset-left, 0);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: z-index.$zi-overlay;
  transition: background-color $transition;
  background-color: rgba(colors.$c-black--100, 0);
  overflow: hidden;
  pointer-events: none;
  text-align: center;

  @include media-queries.respond-to(s) {
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  > .close {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity $transition;
    margin: spacer.$padding-l;
    box-shadow: colors.$bs-base;
    border-radius: border.$br-l;
    background: colors.$c-white;
    width: 3.6rem;
    height: 3.6rem;

    @include media-queries.respond-to(m) {
      margin-left: spacer.$mw-main - 2rem;
    }
  }
  > .showClose {
    opacity: 1;
  }

  > .wrapper {
    transform: translateY(300%);
    transition: transform $transition;
    border-radius: border.$br-l border.$br-l 0 0;
    background-color: colors.$c-white;
    width: 100vw;
    height: auto;
    overflow: hidden;

    @include media-queries.respond-to(m) {
      border-radius: border.$br-l;
      width: spacer.$mw-main;
    }

    > .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: colors.$c-violet--10;
      padding: spacer.$spacer-xl spacer.$spacer-l;

      > .idWrapper {
        position: relative;
        width: 100%;
        max-width: 27rem;
        height: 12rem;

        > .id {
          position: absolute;
          top: 6rem;
          left: 1rem;
          z-index: 2;
          border: border.$b-violet;
          border-radius: border.$br-l;
          background: colors.$c-white;
          padding: spacer.$padding-xs spacer.$padding-m;

          > p {
            margin: 0;

            > span {
              position: relative;
              color: colors.$c-violet--100;
              font-weight: fonts.$fw-medium;

              &::after {
                position: absolute;
                bottom: -0.8rem;
                left: -0.4rem;
                width: 100%;
                content: url("../../assets/underline.svg");
              }
            }
          }
        }

        > img {
          position: absolute;
          top: 0;
          right: 1rem;
          left: auto;
          z-index: 1;
          width: auto;
          height: 100%;
        }
      }
      > p {
        margin-top: spacer.$spacer-l;
      }
    }
    .content {
      padding: spacer.$padding-xxl spacer.$padding-xl;
      text-wrap: balance;

      > h2 {
        margin-top: 0;
      }
    }
  }
  > .showWrapper {
    transform: translateY(0);
  }
}

.showOverlay {
  backdrop-filter: blur(8px) saturate(70%);
  background-color: rgba(colors.$c-black--100, 0.75);
  pointer-events: visible;
}
