@use "sass:math";
@use "media-queries";
@use "variables/border";
@use "variables/colors";
@use "variables/fonts";
@use "variables/gradients";
@use "variables/spacer";
@use "variables/z-index";

html {
  width: 100%;
  height: 100%;
  font-size: math.div(10, 16) * 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  background-color: colors.$c-white;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-anchor: none;
  color: colors.$c-black--100;
  font-weight: fonts.$fw-regular;
  font-size: fonts.$fs-m;
  line-height: fonts.$lh-m;
  font-family: fonts.$ff-base;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

main {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

h1 {
  margin-bottom: spacer.$spacer-l;
  font-weight: fonts.$fw-medium;
  font-size: fonts.$fs-xl;
}

h2 {
  margin-bottom: spacer.$spacer-xxs;
  font-weight: fonts.$fw-medium;
  font-size: fonts.$fs-xl;
}

h3 {
  font-weight: fonts.$fw-regular;
  font-size: fonts.$fs-m;
}

p {
  margin: 0;
  font-weight: fonts.$fw-regular;
  font-size: fonts.$fs-m;
}

a {
  cursor: pointer;
}
